import { Component } from '@angular/core';
import { HouseDetailsBaseComponent } from 'common';

@Component({
  selector: 'app-house-details',
  templateUrl: './house-details.component.html',
  styleUrls: ['./house-details.component.scss']
})

export class HouseDetailsComponent extends HouseDetailsBaseComponent {
}
