import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';

import 'rxjs';

import { FormError, ApiError } from '../models/index';
import { LocaleService } from 'common/seo';
import { ApiLoadingService } from 'common';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class ApiCallService {
  callsInProgress: number = 0;
  agencyUuid: string;

  constructor(
    private httpClient: HttpClient,
    private apiLoadingService: ApiLoadingService,
    private localeService: LocaleService
  ) {}

  getBasePath(): string {
    if (!this.agencyUuid) throw new Error('agencyUuid is not set.');

    return 'v1/site/agencies/' + this.agencyUuid;
  }

  getHttpOptions(): any {
    return {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Language': this.localeService.getCurrentLocale(),
      },
      observe: 'response',
    };
  }

  startApiCall(): void {
    this.callsInProgress++;
    if (this.callsInProgress === 1) this.apiLoadingService.startLoading();
  }

  finishApiCall(): void {
    this.callsInProgress--;
    if (this.callsInProgress < 0) this.callsInProgress = 0;
    if (this.callsInProgress === 0) this.apiLoadingService.stopLoading();
  }

  // Standard HTTP requests
  get(path: string, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .get<any>(
        environment.apiUrl + '/' + basePath + path,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  post(path: string, data: any, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .post<any>(
        environment.apiUrl + '/' + basePath + path,
        data,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  put(path: string, data: any, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .put<any>(
        environment.apiUrl + '/' + basePath + path,
        data,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  delete(path: string, _data?: any, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .delete<any>(
        environment.apiUrl + '/' + basePath + path,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  patch(path: string, data: any, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .patch<any>(
        environment.apiUrl + '/' + basePath + path,
        data,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  head(path: string, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .head<any>(
        environment.apiUrl + '/' + basePath + path,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  options(path: string, basePath?: string): Promise<any> {
    if (basePath === undefined) basePath = this.getBasePath();

    this.startApiCall();

    return this.httpClient
      .options<any>(
        environment.apiUrl + '/' + basePath + path,
        this.getHttpOptions()
      )
      .toPromise()
      .then((response) => this.handleResponse(response))
      .catch((response) => this.handleError(response));
  }

  handleResponse(event: HttpEvent<any>): any {
    this.finishApiCall();

    const response = event as HttpResponse<any>;
    if (response.status === 204) return null;
    return response.body;
  }

  handleError(errorResponse: HttpErrorResponse): void {
    console.error(errorResponse);

    let error;

    this.finishApiCall();

    if (errorResponse.status === 400 || errorResponse.status === 422)
      error = new FormError();
    else error = new ApiError();

    error.code = errorResponse.status;
    error.message = errorResponse.name ? errorResponse.name + ': ' : '';
    error.message += errorResponse.message
      ? errorResponse.message
      : errorResponse;
    if (errorResponse.error) error.errors = errorResponse.error.errors;
    error.api_raw_response = errorResponse;

    throw error;
  }
}
