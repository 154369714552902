import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { ApiCallService } from './api-call.service';
import { Property } from '../models/index';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PropertyService
  extends BaseApiService
  implements Resolve<Property>
{
  constructor(private apiCallService: ApiCallService) {
    super();
  }

  getProperty(agencyUuid: string, friendly_id: string): Promise<Property> {
    this.apiCallService.agencyUuid = agencyUuid;
    return this.apiCallService.get(
      '/new_properties/' + friendly_id + '?token=secrettokenpreview'
    );
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Property> {
    const promise = this.getProperty(
      route.params['agency_uuid'],
      route.params['friendly_id']
    ).catch((error) => {
      throw error;
    });

    return promise;
  }

  generateCharacteristicsString(property: Property): string {
    const str = new Array();
    if (
      property.floor_count && (
        property.property_type === 'apartamente' ||
        property.property_type === 'casevile'
      )
    ) {
      if (property.has_basement) {
        str.push('S');
      }

      if (property.has_semi_basement) {
        str.push('D');
      }

      str.push('P');

      str.push(property.floor_count.toString() + 'E');

      if (property.has_loft) {
        str.push('M');
      }
    }
    return str.join(' + ');
  }
}
