import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  PLATFORM_ID,
} from '@angular/core';
import {
  // Lightbox,
  LightboxConfig,
  IAlbum,
} from 'ngx-lightbox';

/* @ts-ignore-enable */
import Swiper from 'swiper';
// @ts-ignore
import { Navigation, Thumbs } from 'swiper/modules';
import { isPlatformBrowser } from '@angular/common';
import { Image } from 'api';
import { LocaleAwareBaseComponent } from '../seo/locale-aware-base.component';
// @ts-ignore-end

@Component({
  selector: 'app-images-carousel-base',
  templateUrl: './images-carousel-base.component.html',
  styleUrls: ['./images-carousel-base.component.scss'],
})
export class ImagesCarouselBaseComponent
  extends LocaleAwareBaseComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('swiperEl', { static: true }) swiperEl: ElementRef<HTMLDivElement>;
  @ViewChild('swiperThumbsEl', { static: true }) swiperThumbsEl: ElementRef<HTMLDivElement>;
  @ViewChild('swiperPrevEl', { static: true }) swiperPrevEl: ElementRef<HTMLButtonElement>;
  @ViewChild('swiperNextEl', { static: true }) swiperNextEl: ElementRef<HTMLButtonElement>;
  swiper: Swiper;
  swiperThumbs: Swiper;

  @ViewChild('thumbnailsView') thumbnailsView: ElementRef;
  @ViewChild('thumbnailsList') thumbnailsList: ElementRef;

  @Input()
  set images(images: Image[]) {
    this._images = images;
    this._albums = [];

    for (const image of images) {
      const album = {
        src: image.url,
        downloadUrl: image.url,
        caption: image.description,
        thumb: image.width_560_url,
      };

      this._albums.push(album);
    }
  }
  get images(): Image[] {
    return this._images;
  }
  _images: Image[] = [];

  @Output() emitSlideChange: EventEmitter<any> = new EventEmitter<any>();
  private autoSlideInterval: number = 7000;
  private autoSlideTimer: any;
  private _albums: IAlbum[];
  private scrollPosCanChange: boolean = false;
  private thumbnailsScrollVals: number[] = [];
  public isAutoSlideActive: boolean = true;
  public thumbnailsListWidth: number;
  public currentSlideNumber: number;
  public zoomImageParams: any;
  public isZoomDisabled: boolean = false;

  constructor(
    // private _lightbox: Lightbox,
    private _lighboxConfig: LightboxConfig,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject('WINDOW') private window: any
  ) {
    super();
    this.refreshTimer();
  }

  ngOnInit(): void {
    this.currentSlideNumber = 0;
    this.onSlideChange(this.currentSlideNumber);
    this.thumbnailsListWidth = this.images.length * 140;

    if (isPlatformBrowser(this.platformId)) {
      this.initLightbox(this.images);
      this.initSwiper();
    }
  }

  initSwiper() {
    this.swiperThumbs = new Swiper(this.swiperThumbsEl.nativeElement, {
      spaceBetween: 5,
      slidesPerView: 7.5,
      watchSlidesProgress: true,
      centerInsufficientSlides: true,
    });

    this.swiper = new Swiper(this.swiperEl.nativeElement, {
      loop: false,

      // Navigation arrows
      navigation: {
        nextEl: this.swiperNextEl.nativeElement,
        prevEl: this.swiperPrevEl.nativeElement,
      },

      thumbs: {
        swiper: this.swiperThumbs,
      },
      modules: [Navigation, Thumbs],
    });
  }

  ngAfterViewInit(): void {
    if (this.images.length > 1) {
      this.setThumbnailsScrollVals(140);
      this.setThumbnailsListScrollPosition();
      this.scrollPosCanChange = true;
    }
  }

  private setThumbnailsScrollVals(thumbWidth: number): void {
    const imgNr = this.images.length;
    const centerElem = Math.floor(imgNr / 2);

    let centerScrollVal = 0;

    if (this.thumbnailsView)
      centerScrollVal = Math.abs(
        this.thumbnailsView.nativeElement.offsetWidth / 2 -
          this.thumbnailsListWidth / 2
      );
    else this.scrollPosCanChange = false;

    if (imgNr > 1)
      for (let i = 0; i <= imgNr; i++)
        if (imgNr % 2 === 0) {
          if (i <= centerElem)
            this.thumbnailsScrollVals[i] =
              centerScrollVal -
              thumbWidth / 2 -
              (centerElem - i - 1) * thumbWidth;
          else
            this.thumbnailsScrollVals[i] =
              centerScrollVal + thumbWidth / 2 + (i - centerElem) * thumbWidth;
        } else {
          if (i < centerElem)
            this.thumbnailsScrollVals[i] =
              centerScrollVal - (centerElem - i) * thumbWidth;
          else if (i > centerElem)
            this.thumbnailsScrollVals[i] =
              centerScrollVal + (i - centerElem) * thumbWidth;
          else this.thumbnailsScrollVals[i] = centerScrollVal;
        }
  }

  private setThumbnailsListScrollPosition(slideNumber?: number): void {
    if (this.images.length > 1 && this.thumbnailsList)
      if (!slideNumber) this.thumbnailsList.nativeElement.scrollLeft = 0;
      else
        this.thumbnailsList.nativeElement.scrollLeft =
          this.thumbnailsScrollVals[slideNumber];
  }

  public open(index: number, event: any): void {
    if (event) event.stopPropagation();

    if (this.window) this._lighboxConfig.positionFromTop = 20;

    // this._lightbox.open(this._albums, index, { wrapAround: true, showImageNumberLabel: true });
    if (this.window) this.window.open(this._albums[index].src, '_blank');
  }

  private startAutoRotation(): void {
    if (this.images.length > 1)
      if (this.window)
        this.autoSlideTimer = setInterval(() => {
          if (this.isAutoSlideActive) {
            this.getNext(undefined);
          }
        }, this.autoSlideInterval);
  }

  private initLightbox(_images: Image[]): void {
    this._lighboxConfig.fadeDuration = 1;
  }

  private refreshTimer(): void {
    clearInterval(this.autoSlideTimer);

    this.startAutoRotation();
  }

  private onSlideChange(slideNumber: number): void {
    this.emitSlideChange.emit({
      imageUrl: this.images[slideNumber].url,
      slideNumber: slideNumber,
    });
  }

  updateCurrentSlide(slideNumber: number, event: any): void {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    this.currentSlideNumber = slideNumber;
    this.onSlideChange(this.currentSlideNumber);
    this.refreshTimer();

    if (this.window)
      if (this.window.innerWidth < 600)
        // Same as hover-scroll.directive (should not interfere)
        this.updateThumbnailsListScrollPosition(slideNumber);
  }

  private updateThumbnailsListScrollPosition(slideNumber: number): void {
    if (this.scrollPosCanChange && this.images.length > 1)
      this.setThumbnailsListScrollPosition(slideNumber);
  }

  public getPrevious(event: any): void {
    if (event) event.stopPropagation();

    if (this.currentSlideNumber === 0)
      this.currentSlideNumber = this.images.length - 1;
    else this.currentSlideNumber--;

    this.onSlideChange(this.currentSlideNumber);
    this.refreshTimer();

    this.updateThumbnailsListScrollPosition(this.currentSlideNumber);
  }

  public getNext(event: any): void {
    if (event) event.stopPropagation();

    if (this.currentSlideNumber === this.images.length - 1)
      this.currentSlideNumber = 0;
    else this.currentSlideNumber++;

    this.onSlideChange(this.currentSlideNumber);
    this.refreshTimer();

    this.updateThumbnailsListScrollPosition(this.currentSlideNumber);
  }
}
