import { Component } from '@angular/core';
import { LocaleAwareBaseComponent } from 'common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent extends LocaleAwareBaseComponent {
  today: Date = new Date();
}
