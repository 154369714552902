import { Directive } from '@angular/core';
import { environment } from '../environments/environment';

@Directive()
export class BaseApiService {
  validateId(id: number, name: string): boolean {
    if (!this.checkId(id)) {
      const extra = {};
      extra[name] = String(id);
      this.reportError(new Error('API id is invalid.'), extra);
      return false;
    } else {
      return true;
    }
  }

  validateString(str: string, name: string): boolean {
    if (!this.checkString(str)) {
      const extra = {};
      extra[name] = String(str);
      this.reportError(new Error('API string is invalid.'), extra);
      return false;
    } else {
      return true;
    }
  }

  checkString(str: string): boolean {
    if (typeof str === 'string' || typeof str === 'number') {
      if (typeof str === 'number') str = String(str);

      if (str.length) return true;
    }

    return false;
  }

  checkId(id: number): boolean {
    if (!id || isNaN(id) || id <= 0) return false;
    return true;
  }

  private reportError(error: Error, _extra: {}): void {
    if (!environment.production) console.error(error);
  }
}
