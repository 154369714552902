<header
  *ngIf="agencyService.agency"
  class="custom-container no-page-break flex flex-col justify-between items-center md:flex-row print:flex-row"
>
  <div>
    <a
      *ngIf="agencyService.agency.website_url && agencyService.agency.logo && agencyService.agency.logo.url"
      class="agency-url"
      [href]="agencyService.agency.website_url"
      target="_blank"
    >
      <img
        class="logo"
        [src]="agencyService.agency.logo.url"
        loading="eager"
      >
    </a>
    <img
      *ngIf="!agencyService.agency.website_url && agencyService.agency.logo && agencyService.agency.logo.url"
      class="logo"
      [src]="agencyService.agency.logo.url"
      loading="eager"
    >
    <h1 *ngIf="!agencyService.agency.logo || !agencyService.agency.logo.url">
      {{ agencyService.agency.name }}
    </h1>
  </div>

  <div
    class="flex flex-col items-center md:items-end justify-center"
  >
    <h3>
      Contact
      {{ 'model.agency.account_type.' + agencyService.agency.account_type | translate }}
    </h3>
    <a
      *ngIf="agencyService.agency.phone"
      [href]="'tel:' + agencyService.agency.phone"
    >
      {{ agencyService.agency.phone | beautify }}
    </a>
    <a
      *ngIf="agencyService.agency.email"
      [href]="'mailto:' + agencyService.agency.email"
    >
      {{ agencyService.agency.email }}
    </a>
    <a
      *ngIf="agencyService.agency.website_url"
      [href]="agencyService.agency.website_url"
      target="_blank"
    >
      {{ agencyUrl() }}
    </a>
  </div>
</header>
