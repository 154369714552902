<form
  (submit)="onSubmit()"
  ngNativeValidate
>
  <div *ngIf="!success">
    <div class="flex flex-col justify-between items-center">
      <div class="grid grid-cols-2 gap-2">
        <div>
          <mat-form-field class="w-full">
            <mat-label>
              Prenume
            </mat-label>
            <input
              matInput
              [(ngModel)]="contact.first_name"
              name="first_name"
              required
              autocomplete="given-name"
            >
          </mat-form-field>
          <app-form-error [errors]="formError.errors.first_name"></app-form-error>
        </div>
        <div class="w-full">
          <mat-form-field class="w-full">
            <mat-label>
              Nume
            </mat-label>
            <input
              matInput
              [(ngModel)]="contact.last_name"
              name="last_name"
              autocomplete="family-name"
            >
          </mat-form-field>
          <app-form-error [errors]="formError.errors.last_name"></app-form-error>
        </div>
      </div>
      <div class="w-full">
        <mat-form-field class="w-full">
          <mat-label>
            Email
          </mat-label>
          <input
            matInput
            [(ngModel)]="contact.email"
            name="email"
            type="email"
          >
        </mat-form-field>
        <app-form-error [errors]="formError.errors.email"></app-form-error>
      </div>
      <div class="w-full">
        <mat-form-field class="w-full">
          <mat-label>
            Telefon
          </mat-label>
          <input
            matInput
            [(ngModel)]="contact.phone"
            name="phone"
            required
            type="tel"
            autocomplete="tel-national"
          >
        </mat-form-field>
        <app-form-error [errors]="formError.errors.phone"></app-form-error>
      </div>
      <div class="w-full">
        <mat-form-field class="w-full">
          <mat-label>
            Mesaj
          </mat-label>
          <textarea
            matInput
            [(ngModel)]="contact.message"
            name="message"
          >
          </textarea>
        </mat-form-field>
        <app-form-error [errors]="formError.errors.message"></app-form-error>
      </div>

      <div class="w-full mb-2.5">
        <mat-checkbox
          [(ngModel)]="contact.marketing_accepted"
          name="marketing_accepted"
          value="basic_checkbox"
          required
        >
          <span class="leading-none">
            Sunt de acord cu prelucrarea datelor și cu termenii prevăzuți în:
            <a
              [href]="agencyService.agency.website_gdpr_file.url"
              target="_blank"
              class="underline"
            >
              Politică de Confidențialitate
            </a>
          </span>
        </mat-checkbox>
        <app-form-error [errors]="formError.errors.marketing_accepted && canShowErrors ? ['pentru a trimite un mesaj, această bifă este obligatorie.'] : formError.errors.marketing_accepted"></app-form-error>
      </div>

      <button
        type="submit"
        mat-raised-button
        color="primary"
      >
        Trimite mesaj
      </button>
    </div>
  </div>

  <div *ngIf="success" class="text-center">
    <div class="mb-1 text-base font-bold">
      Mesaj trimis cu succes!
    </div>
    <button
      mat-stroked-button
      type="button"
      (click)="success = !success;"
    >
      Trimite alt mesaj
    </button>
  </div>
</form>
