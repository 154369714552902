import { Params } from '@angular/router';
import { Directive } from '@angular/core';
import { SeoAwareBaseComponent } from './seo/seo-aware-base.component';
import { environment } from './environments/environment';

@Directive()
export abstract class BaseComponent extends SeoAwareBaseComponent {
  validateRouteDataParams(data: any, names: string[]): boolean {
    for (const name in names) {
      if (!data[names[name]]) {
        this.reportError(new Error(`Route ${names[name]} param is invalid.`));
        return false;
      }
    }
    return true;
  }

  validateRouteQueryParams(params: Params): boolean {
    for (const field in params) {
      if (typeof field === 'number' && !this.checkNumber(field)) {
        this.reportError(new Error(`Route ${field} number param is invalid.`));
        return false;
      } else {
        if (typeof field === 'string' && !this.checkString(field)) {
          this.reportError(
            new Error(`Route ${field} string param is invalid.`)
          );
          return false;
        }
      }
    }
    return true;
  }

  checkNumber(nr: number): boolean {
    if (!nr || isNaN(nr) || nr <= 0) {
      return false;
    }
    return true;
  }

  checkString(str: string): boolean {
    if (str.length) {
      return true;
    }
    return false;
  }

  private reportError(error: Error): void {
    if (!environment.production) {
      console.error(error);
    }
  }

  hasBack(): boolean {
    return history.length > 1;
  }

  goBack(): void {
    history.back();
  }
}
