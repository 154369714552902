import {
  Component,
  Input,
  AfterViewInit,
  ElementRef,
  ViewChild,
  PLATFORM_ID,
  Inject,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MapMarker } from './map-marker.model';

declare var ol: any;

// https://github.com/pzaenger/angular-openlayers
@Component({
  selector: 'app-open-street-map',
  templateUrl: './open-street-map.component.html',
  styleUrls: ['open-street-map.component.scss'],
})
export class OpenStreetMapComponent implements AfterViewInit {
  @Input()
  set mapMarker(mapMarker: MapMarker) {
    const mapMarkerDefault = new MapMarker();

    for (const item in mapMarkerDefault) {
      if (mapMarkerDefault.hasOwnProperty(item))
        if (mapMarkerDefault[item] && mapMarker[item] === undefined)
          mapMarker[item] = mapMarkerDefault[item];
    }

    this._mapMarker = mapMarker;

    if (this.map) {
      this.setView();
      this.addLayers();
    }
  }
  get mapMarker(): MapMarker {
    return this._mapMarker;
  }
  _mapMarker: MapMarker;

  @ViewChild('mapWrapper') mapWrapper: ElementRef;

  map: any;

  mapElId: string = 'osm-' + Math.floor(Math.random() * 10000);

  constructor(
    @Inject(PLATFORM_ID) public platformId: object,
    @Inject('WINDOW') public window: any
  ) {}

  ngAfterViewInit(): void {
    this.map = new ol.Map({
      target: this.mapElId,
      interactions: ol.interaction.defaults({
        dragPan: false,
        mouseWheelZoom: false,
      }),
    });

    this.setView();
    this.addLayers();

    // https://codepen.io/HansB1984/pen/OJLogga
    // Strg+MouseWheel Zoom
    this.map.addInteraction(
      new ol.interaction.MouseWheelZoom({
        condition: (e) => e.originalEvent.ctrlKey,
      })
    );

    // desktop: normal; mobile: 2-finger pan to start
    this.map.addInteraction(
      new ol.interaction.DragPan({
        condition: function (e: any): boolean {
          return (
            ol.events.condition.noModifierKeys(e) &&
            (!/Mobi|Android/i.test(navigator.userAgent) ||
              this.targetPointers.length === 2)
          );
        },
      })
    );

    // the quick-changing holder of last touchmove y
    let lastTouchY = null;

    const div = this.mapWrapper.nativeElement;
    const scrollerBlades =
      document.scrollingElement || document.documentElement;

    div.addEventListener('touchmove', (e) => {
      e.preventDefault();
      const touches = e.touches || e.changedTouches;
      // on 1-finger-touchmove, scroll and take note of prev y
      if (touches.length === 1) {
        if (lastTouchY !== null) {
          const by = lastTouchY - touches[0].clientY;
          scrollerBlades.scrollTop += by;
        }
        lastTouchY = touches[0].clientY;
      }
    });

    // on touchend, reset y
    div.addEventListener('touchend', () => {
      lastTouchY = null;
    });

    this.map.updateSize();
  }

  generateCircle(latitude: number, longitude: number): any {
    const view = this.map.getView();
    const projection = view.getProjection();
    const resolutionAtEquator = view.getResolution();
    const center = view.getCenter();
    const pointResolution = ol.proj.getPointResolution(
      projection,
      resolutionAtEquator,
      center
    );
    const resolutionFactor = resolutionAtEquator / pointResolution;
    const radius = (250 / ol.proj.Units.METERS_PER_UNIT.m) * resolutionFactor;

    const circleFeature = new ol.Feature(
      new ol.geom.Circle(ol.proj.fromLonLat([longitude, latitude]), radius)
    );
    const circleStyle = new ol.style.Style({
      fill: new ol.style.Fill({ color: 'rgba(255, 0, 0, .3)' }),
      stroke: new ol.style.Stroke({ color: 'rgb(255, 0, .9)', width: 2 }),
    });
    circleFeature.setStyle(circleStyle);

    const layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [circleFeature],
      }),
    });

    return layer;
  }

  generateMarker(latitude: number, longitude: number): any {
    const iconFeature = new ol.Feature({
      geometry: new ol.geom.Point(ol.proj.fromLonLat([longitude, latitude])),
    });

    const iconStyle = new ol.style.Style({
      image: new ol.style.Icon({
        anchor: [0.5, 1],
        src: '//cdn.mapmarker.io/api/v1/pin?size=50&hoffset=1',
      }),
    });
    iconFeature.setStyle(iconStyle);

    const layer = new ol.layer.Vector({
      source: new ol.source.Vector({
        features: [iconFeature],
      }),
    });

    this.map.on('click', (event) => {
      this.map.forEachFeatureAtPixel(event.pixel, (feature) => {
        if (feature.getId() === iconFeature.getId())
          this.clickedMarker(latitude, longitude);
      });
    });

    return layer;
  }

  clickedMarker(latitude: number, longitude: number): void {
    if (isPlatformBrowser(this.platformId) && this.window) {
      this.window.open(
        'https://maps.google.com?daddr=' +
          latitude +
          ',' +
          longitude +
          '&amp;ll='
      );
    }
  }

  setView(): void {
    this.map.setView(
      new ol.View({
        center: ol.proj.fromLonLat([
          this.mapMarker.longitude,
          this.mapMarker.latitude,
        ]),
        zoom: this.mapMarker.zoom,
        constrainResolution: true,
      })
    );
  }

  addLayers(): void {
    if (!this.map) return;

    this.map.addLayer(
      new ol.layer.Tile({
        source: new ol.source.OSM(),
      })
    );

    if (this.mapMarker.approximate)
      this.map.addLayer(
        this.generateCircle(this.mapMarker.latitude, this.mapMarker.longitude)
      );
    else
      this.map.addLayer(
        this.generateMarker(this.mapMarker.latitude, this.mapMarker.longitude)
      );
  }
}
