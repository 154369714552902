import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiCallService } from './api-call.service';
import { Agency } from '../models/index';

@Injectable({ providedIn: 'root' })
export class AgencyOfferService implements Resolve<Agency> {
  constructor(private apiCallService: ApiCallService) {}

  public getAgencyOffer(agencyUuid: string): Promise<Agency> {
    this.apiCallService.agencyUuid = agencyUuid;
    const promise = this.apiCallService.get('/offer');
    return promise;
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Agency> {
    const promise = this.getAgencyOffer(route.params['agency_uuid']).catch(
      (error) => {
        throw error;
      }
    );

    return promise;
  }
}
