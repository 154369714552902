import { Component, Input } from '@angular/core';
import { Project } from 'api';

@Component({
  selector: 'app-map-plan-project-mobile-base',
  templateUrl: './map-plan-project-mobile-base.component.html',
  styleUrls: ['./map-plan-project-mobile-base.component.scss'],
})
export class MapPlanProjectMobileBaseComponent {
  @Input() projects: Project[];
}
