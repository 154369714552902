import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Project } from '../models/index';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ApiLoadingService } from 'common';

@Injectable({ providedIn: 'root' })
export class ProjectService implements Resolve<Project[]> {
  constructor(
    private apiLoadingService: ApiLoadingService,
    private apiCallService: ApiCallService
  ) {}

  getProjects(projectSearch?: any, page?: number): Promise<Project[]> {
    let path = '/projects';

    if (projectSearch) {
      throw new Error('Not implemented');
    } else if (page) {
      path += '?page=' + page;
    }

    return this.apiCallService.get(path);
  }

  getProject(id: number): Promise<Project> {
    return this.apiCallService.get('/projects/' + id);
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Project[]> {
    let id = route.params['id']
      ? route.params['id']
      : route.params['project_id'];
    const projectSearch = route.data['projectSearch'];

    if (id === undefined) id = route.data['project_id'];

    let promise;

    if (id === undefined) {
      promise = projectSearch
        ? this.getProjects(projectSearch)
        : this.getProjects();
    } else {
      promise = this.getProject(id).catch((error) => {
        throw error;
      });
    }

    this.apiLoadingService.startLoading(promise);

    return promise;
  }
}
