<iframe
  *ngIf="iframeSrc"
  [src]="iframeSrc"
  width="100%"
  height="600"
  frameborder="0"
  scrolling="no"
  marginheight="0"
  marginwidth="0"
>
  <a
    [href]="
      'https://maps.google.com?daddr=' + latitude + ',' + longitude + '&amp;ll='
    "
    target="_blank"
  >
    Vezi localizare pe hartă
  </a>
</iframe>
