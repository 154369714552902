<div class="ap-wrap">
  <div class="imgmap-frontend-image">
    <div class="home-imagemap-head-bg"></div>
    <div style="display: block; position: relative">
      <img
        [src]="agency.map_plan.url"
        usemap="#ap-map"
        id="ap-img"
        class="ap-img"
      />
    </div>
    <map name="ap-map">
      <area
        *ngFor="
          let project of projects;
          trackBy: listTrackingService.disableUpdate
        "
        data-type="link"
        [href]="getHref(project.id)"
        [attr.data-tooltip]="project.name"
        data-fill-color="E4BD62"
        data-fill-opacity="0.5"
        data-stroke-color="fefefe"
        data-stroke-opacity="0.8"
        data-stroke-width="2"
        [attr.data-mapkey]="'area-' + project.id"
        shape="poly"
        [attr.coords]="project.area.join(',')"
      />
    </map>
  </div>
</div>

<div class="flex md:hidden flex-col items-center my-10">
  <h1 class="text-center mb-5 text-2xl font-bold">Selectează clădirea:</h1>

  <a
    *ngFor="
      let project of projects;
      trackBy: listTrackingService.disableUpdate;
      block
    "
    [routerLink]="'/' + getHref(project.id)"
    class="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded w-48 mb-5 text-center"
  >
    {{ project.name }}
  </a>
</div>
