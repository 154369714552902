import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'beautify' })
export class BeautifyPhonePipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return (
        '0' +
        value
          .substring(3)
          .match(/.{1,3}/g)
          .join(' ')
      );
    }
  }
}
