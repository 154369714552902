import { Component, Input, OnInit } from '@angular/core';
import { AgencyService, Contact, ContactService, FormError } from 'api';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
})
export class ContactFormComponent implements OnInit {
  @Input() propertyId: number;

  submitInProgress: boolean;
  success: boolean;
  contact: Contact = new Contact();
  formError: FormError = new FormError();
  canShowErrors: boolean = true;

  constructor(
    public agencyService: AgencyService,
    private contactService: ContactService,
  ) { }

  ngOnInit(): void {
    this.success = false;
    this.submitInProgress = false;
  }

  onSubmit(): void {
    if (this.submitInProgress || this.success)
      return;

    this.formError = new FormError();

    this.contact.property_id = this.propertyId;

    this.contactService.send(this.contact)
      .then(() => {
        this.contact = new Contact();
        this.submitInProgress = false;
        this.success = true;
      })
      .catch(reposnse => {
        this.formError = reposnse;
        this.submitInProgress = false;
        this.success = false;
      });
  }
}
