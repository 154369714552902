import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  public currentRoute: string = '/';

  constructor(
    private translate: TranslateService,
    router: Router,
    @Inject('WINDOW') private window: any
  ) {

    if (router) {
      router.events.subscribe(s => {
        if (s instanceof NavigationEnd) {
          const tree = router.parseUrl(router.url);
          this.currentRoute = router.url;

          if (!tree.fragment && this.window)
            this.window.scrollTo(0, 0);
        }
      });
    }

    this.translate.setDefaultLang('ro');
    this.translate.use('ro');
  }
}
