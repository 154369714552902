import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { Contact } from './../models/index';

@Injectable({ providedIn: 'root' })
export class ContactService {
  constructor(private apiCallService: ApiCallService) { }

  send(contact: Contact): Promise<any> {
    return this.apiCallService.post('/contact', contact);
  }
}
