import { Injectable } from '@angular/core';

@Injectable()
export class ListTrackingService {
  trackById(_index: number, item: any): number {
    return item.id;
  }

  trackByTimestamp(_index: number, item: any): Date {
    return item.timestamp;
  }

  disableUpdate(index: number, _item: any): number {
    return index;
  }
}
