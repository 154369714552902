import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AgencyService } from 'api';
import { LocaleAwareBaseComponent } from 'common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent extends LocaleAwareBaseComponent {
  constructor(
    public agencyService: AgencyService,
    @Inject(Title) private title: any
  ) {
    super();

    if (this.agencyService.agency)
      this.title.setTitle(this.agencyService.agency.name);
  }

  agencyUrl(): string {
    if (!this.agencyService.agency.website_url)
      return null;
    const { hostname } = new URL(this.agencyService.agency.website_url);
    return hostname;
  }
}
