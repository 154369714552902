import { Component, OnInit, Injector } from '@angular/core';
import { BaseComponent } from 'common';
import { ActivatedRoute } from '@angular/router';
import { Offer, OfferProperty, OfferPropertyService } from 'api';

@Component({
  selector: 'app-offer-details',
  templateUrl: './offer-details.component.html',
  styleUrls: ['./offer-details.component.scss']
})
export class OfferDetailsComponent extends BaseComponent implements OnInit {
  offer: Offer;

  constructor(
    injector: Injector,
    private offerPropertyService: OfferPropertyService,
    private route: ActivatedRoute
  ) {
    super(injector);
  }

  sendFeedback(offerProperty: OfferProperty, status: string): void {
    offerProperty.status = status;
    this.offerPropertyService.sendOfferPropertyFeedback(
      this.route.snapshot.params['agency_uuid'],
      this.route.snapshot.params['uuid'],
      offerProperty
    ).then(response => offerProperty.status = response.status);
  }

  ngOnInit(): void {
    this.offer = this.route.snapshot.data['offer'];
  }
}
