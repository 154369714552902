<div class="ap-wrap">
  <div class="imgmap-frontend-image">
    <div class="home-imagemap-head-bg"></div>
    <div style="display: block; position: relative">
      <img
        [src]="buildingFloor.map_plan.url"
        usemap="#ap-map"
        id="ap-img"
        class="ap-img"
      />
    </div>
    <map name="ap-map">
      <area
        *ngFor="
          let property of buildingFloor.properties;
          trackBy: listTrackingService.disableUpdate
        "
        data-type="link"
        [href]="getHref(property.friendly_id)"
        [attr.data-tooltip]="propertyToolTip(property)"
        data-fill-color="E4BD62"
        data-fill-opacity="0.5"
        data-stroke-color="fefefe"
        data-stroke-opacity="0.8"
        data-stroke-width="2"
        [attr.data-mapkey]="'area-' + property.friendly_id"
        shape="poly"
        [attr.coords]="property.area.join(',')"
        [class.selected]="
          canSeeAvailability() && property.availability != 'available'
        "
      />
    </map>
  </div>

  <div class="text-center font-bold text-gray-800 mt-3 md:hidden">
    Mărește planul de etaj cu 2 degete
  </div>
</div>

<div class="block md:hidden mt-2 mb-5 px-5">
  <h1 class="text-center mb-5 text-2xl font-bold">
    Locuințe {{ buildingFloor.floor_value }}:
  </h1>

  <a
    *ngFor="
      let property of buildingFloor.properties;
      trackBy: listTrackingService.disableUpdate
    "
    [routerLink]="'/' + getHref(property.friendly_id)"
    class="w-full block border-b border-gray-400 pb-5 mb-5 last:border-b-0 last:pb-0 last:mb-0"
  >
    <app-property-card [property]="property"></app-property-card>
  </a>
</div>
