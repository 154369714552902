<div class="bloc-list container-fluid">
  <div class="row">
    <div
      *ngFor="let project of projects"
      class="bloc-list-item"
      style="width: 50%"
    >
      <a [routerLink]="['bloc', project.id]">
        <span
          class="thumb"
          [style.background-image]="'url(' + project.map_plan.url + ')'"
        >
        </span>
        <span>
          {{ project.name }}
        </span>
      </a>
    </div>
  </div>
</div>
