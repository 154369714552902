import {
  Inject,
  NgZone,
  AfterViewInit,
  OnDestroy,
  Component,
  Input,
} from '@angular/core';
import { MapPlanBase } from './map-plan-base.model';
import { AgencyService, BuildingFloor, Project } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-building-base',
  templateUrl: './map-plan-building-base.component.html',
})
export class MapPlanBuildingBaseComponent
  extends MapPlanBase
  implements AfterViewInit, OnDestroy
{
  @Input() project: Project;
  @Input() buildingFloors: BuildingFloor[] = [];

  constructor(
    private agencyService: AgencyService,
    public zone: NgZone,
    @Inject('WINDOW') public window: any,
    public listTrackingService: ListTrackingService
  ) {
    super(zone, window);
  }

  ngAfterViewInit(): void {
    this.runImgMappingScript();
  }

  ngOnDestroy(): void {
    this.removeImgMappingScript();
  }

  getHref(buildingFloorId: number): string {
    return (
      this.agencyService.agency.uuid +
      '/2d/building/' +
      this.project.id +
      '/' +
      buildingFloorId
    );
  }
}
