import { Injectable } from '@angular/core';
import { ApiCallService } from './api-call.service';
import { BuildingFloor } from '../models/index';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { ApiLoadingService } from 'common';

@Injectable({ providedIn: 'root' })
export class BuildingFloorService implements Resolve<BuildingFloor[]> {
  constructor(
    private apiLoadingService: ApiLoadingService,
    private apiCallService: ApiCallService,
    private router: Router
  ) {}

  getBuildingFloors(projectId: number): Promise<BuildingFloor[]> {
    return this.apiCallService.get(
      '/projects/' + projectId + '/building_floors'
    );
  }

  getBuildingFloor(id: number): Promise<BuildingFloor[]> {
    return this.apiCallService.get('/building_floors/' + id);
  }

  resolve(route: ActivatedRouteSnapshot): Promise<BuildingFloor[]> {
    const id = route.params['building_floor_id'];
    let promise;

    if (id === undefined) {
      let projectId = route.params['project_id'];
      projectId =
        !projectId ? route.parent.params['project_id'] : projectId;
      projectId = !projectId ? route.data['project_id'] : projectId;
      promise = this.getBuildingFloors(projectId);
    } else {
      promise = this.getBuildingFloor(id).catch((_) => {
        this.router.navigate(['/']);
        return false;
      });
    }

    this.apiLoadingService.startLoading(promise);

    return promise;
  }
}
