import { Image } from './image.model';

export class Agency {
  uuid: string;
  offer_promo_copy: string;
  address: string;
  account_type: string;
  city_name: string;
  county_name: string;
  logo: Image;
  map_plan: Image;
  email: string;
  website_url: string;
  facebook_link: string;
  instagram_link: string;
  pinterest_link: string;
  vimeo_link: string;
  youtube_link: string;
  gplus_link: string;
  latitude: number;
  longitude: number;
  name: string;
  phone: string;
  web_page_types: string[] = [];
  social_header_visibility: boolean;
  social_footer_visibility: boolean;
  cookie_gdpr_file: {
    url: null;
  };
  website_gdpr_file: {
    url: null;
  };
  offer_images: Image[] = [];
  offer_project_description_html: string;
  exposed_2d_sections: string[];
}
