<div class="print:hidden">
  <div #swiperEl class="swiper swiper-container">
    <div class="swiper-wrapper">
      <div *ngFor="let image of images; let i = index" class="swiper-slide">
        <img
          loading="eager"
          [src]="image.url"
          (click)="open(i, $event)"
        />
      </div>
    </div>

    <button class="swiper-button-prev dn--print" #swiperPrevEl>
      <svg
        enable-background="new 0 0 40 40"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m0 0h40v40h-40z" fill="#ecc23c" />
        <path d="m26.612 27.635-13.224-7.634 13.224-7.636z" fill="#fff" />
      </svg>
    </button>
    <button class="swiper-button-next dn--print" #swiperNextEl>
      <svg
        enable-background="new 0 0 40 40"
        height="40"
        viewBox="0 0 40 40"
        width="40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m0 0h40v40h-40z" fill="#ecc23c" />
        <path d="m13.388 12.365 13.224 7.634-13.224 7.636z" fill="#fff" />
      </svg>
    </button>
  </div>

  <div #swiperThumbsEl class="swiper swiper-thumbs swiper-container">
    <div class="swiper-wrapper">
      <ng-container *ngIf="images.length > 1">
        <div *ngFor="let image of images" class="swiper-slide">
          <img
            loading="eager"
            [src]="image.width_320_url"
          />
        </div>
      </ng-container>
    </div>
  </div>
</div>

<div class="print:block hidden no-page-break">
  <a *ngIf="images.length > 0" [href]="images[0].url">
    <img
      loading="eager"
      class="w-full aspect-video object-contain"
      [src]="images[0].url"
    />
  </a>

  <div
    class="flex gap-1.5 flex-row max-w-full overflow-hidden justify-center my-5"
  >
    <a *ngFor="let image of images" [href]="image.url">
      <img
        loading="eager"
        class="aspect-video object-cover h-24"
        [src]="image.width_320_url"
      />
    </a>
  </div>
</div>
