import { TransferState, HammerModule, BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { HouseDetailsComponent } from './houses/house-details.component';
import { OfferDetailsComponent } from './offers/offer-details.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HeaderComponent } from './shared/header/header.component';

import { MatIconModule } from '@angular/material/icon';
import { CommonThemeModule } from 'common';

// Translate
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { exportTranslateStaticLoader } from 'libs/common/src/i18n/translate-browser-loader.service';

import localeRoExtra from '@angular/common/locales/extra/ro';
import { registerLocaleData } from '@angular/common';
import localeRo from '@angular/common/locales/ro';
registerLocaleData(localeRo, 'ro-RO', localeRoExtra);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HouseDetailsComponent,
    OfferDetailsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: exportTranslateStaticLoader,
        deps: [HttpClient, TransferState]
      }
    }),
    CommonThemeModule,
    MatIconModule,
    HammerModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'ro-RO'},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
