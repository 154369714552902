import { Image } from './image.model';
import { Document } from './document.model';
import { City } from './city.model';
import { County } from './county.model';
import { Zone } from './zone.model';
import { Project } from './project.model';
import { Agency } from './agency.model';
import { Agent } from './agent.model';

export class Property {
  id: number;
  website_address: string;
  friendly_id: string;
  embed_html: string;
  images: Image[] = [];
  documents: Document[] = [];
  availability: string;
  transaction_status: string;
  promo: boolean = false;
  kitchen_type: string;
  internal_code: string;
  agent: Agent;
  title: string;
  selected_orientations: string[] = [];
  description: string;
  property_type: string;
  price_type_copy: string;
  county_id: number;
  agent_id: number;
  city_id: number;
  zone_id: number;
  website_latitude: number;
  website_longitude: number;
  project_name: string;
  sublocality: string;
  address: string;
  website_unexact_location: boolean = false;
  for_sale: boolean = false;
  to_let: boolean = false;
  commission: boolean;
  commission_description: string;
  home_type: string;
  building_type_id: number;
  floor_id: number;
  layout_id: number;
  confort_id: number;
  roof_id: number;
  room_count: number;
  balcony_size_sqm: number;
  total_size_sqm: number;
  garden_size_sqm: number;
  usable_size_sqm: number;
  built_size_sqm: number;
  year_built: number;
  built_state: string;
  bathroom_count: number;
  has_semi_basement: boolean = false;
  has_basement: boolean = false;
  has_loft: boolean = false;
  floor_count: number;
  land_type_id: number;
  land_class_id: number;
  front_length_m: number;
  land_area_sqm: number;
  sale_price: string;
  promo_sale_price: any;
  sale_price_new: string;
  price_per_sqm: number;
  let_price: string;
  let_price_new: string;
  edit: boolean = false;
  selected: boolean = false;
  created_at: string;
  updated_at: string;
  note: string;
  operation: string;
  vat: string;
  sale_vat: string;
  let_vat: string;
  city: City;
  county: County;
  zone: Zone;
  project: Project;
  project_id: number;
  agency: Agency;
  type_name: string;
  number: string;
  currency_id: string;
  floor_value: string;
  layout_value: string;
  sale_currency_value: string;
  has_mezzanine: boolean = false;
  construction_status: string;
  construction_status_changed_at: string;
  construction_status_changed_by_agent: Agent;
  map_plan: Image;
  representation: string;
  balcony_count: number;
  closed_balcony_count: number;
  kitchen_count: number;
  garage_count: number;
  terrace_count: number;
  front_count: number;
  terrace_size_sqm: number;
  parking_spots_count: number;
  building_material_id: number;
  building_material_value: string;
  street_front_count: number;
  pot: number;
  cut: number;
  allowed_height: number;
  has_buildings: boolean;
  office_class_id: number;
  land_info_source_value: string;
  land_info_source_id: number;
  price_negociable: boolean;
  space_height: number;
  showcase: boolean;
  showcase_length_m: number;
  office_class_value: string;
  confort_value: string;
  land_class_value: string;
  land_type_value: string;
  roof_value: string;
  building_type_value: string;
  virtual_tour_url: string;
  area: string[] = [];
  utility_values: string[] = [];
  facility_values: string[] = [];
  finish_values: string[] = [];
  service_values: string[] = [];
  roof_material_values: string[] = [];
  zone_detail_values: string[] = [];
  destination_values: string[] = [];
  other_characteristic_values: string[] = [];
}
