<footer>
  <div class="custom-container no-page-break flex flex-col sm:flex-row justify-between items-center gap-2.5">
    <h4 class="rights">©{{ today.getFullYear() }} All rights reserved</h4>
    <a
      class="credits"
      fxLayout="column"
      fxLayout.gt-xs="row"
      fxLayoutAlign="end center"
      fxLayoutGap="5px"
      href="https://vaunt.ro/"
      rel="nofollow"
      target="_blank"
    >
      <h4 class="text-center mb-1">Web solution by</h4>
      <img
        loading="eager"
        src="/assets/icons/vaunt_logo.svg"
        alt="Vaunt"
        class="aw-logo"
      />
    </a>
  </div>
</footer>
