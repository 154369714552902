import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ApiCallService } from './api-call.service';
import { Agency } from '../models/index';

@Injectable({ providedIn: 'root' })
export class AgencyService implements Resolve<Agency> {
  _agency: Agency;

  get agency(): Agency {
    return this._agency;
  }

  constructor(private apiCallService: ApiCallService) {}

  public getAgency(agencyUuid: string): Promise<Agency> {
    this.apiCallService.agencyUuid = agencyUuid;
    const promise = this.apiCallService.get('');

    if (promise) {
      promise.then((response) => {
        this._agency = response;
      });
    }

    return promise;
  }

  resolve(route: ActivatedRouteSnapshot): Promise<Agency> {
    const promise = this.getAgency(route.params['agency_uuid']).catch(
      (error) => {
        throw error;
      }
    );

    return promise;
  }
}
