<div class="w-full">
  <div class="relative">
    <img
      *ngIf="property.images[0]"
      [src]="property.images[0].width_560_url"
      [alt]="property.title"
      class="w-full pb-4"
    />
    <div
      *ngIf="canSeeAvailability()"
      class="font-bold absolute top-3 right-3 bg-black text-white py-1 px-3"
    >
      {{ propertyAvailability(property.availability) }}
    </div>
  </div>

  <div class="flex justify-between items-center">
    <div class="text-2xl font-bold">
      {{ property.number }}
    </div>
    <div class="font-bold leading-tight">
      {{ property.title }}
    </div>
  </div>

  <div class="text-center flex justify-center items-center gap-1">
    <span> vezi detalii </span>
    <svg class="h-2.5 inline" viewBox="0 0 330 330">
      <path
        id="XMLID_222_"
        d="M250.606,154.389l-150-149.996c-5.857-5.858-15.355-5.858-21.213,0.001
        c-5.857,5.858-5.857,15.355,0.001,21.213l139.393,139.39L79.393,304.394c-5.857,5.858-5.857,15.355,0.001,21.213
        C82.322,328.536,86.161,330,90,330s7.678-1.464,10.607-4.394l149.999-150.004c2.814-2.813,4.394-6.628,4.394-10.606
        C255,161.018,253.42,157.202,250.606,154.389z"
      />
    </svg>
  </div>
</div>
