<div *ngIf="errors">
  <ul *ngFor="let error of errors">
    <li>{{ error }}</li>
  </ul>
</div>

<div *ngIf="error">
  <ul>
    <li>{{ error }}</li>
  </ul>
</div>
