import {
  Inject,
  NgZone,
  AfterViewInit,
  OnDestroy,
  Component,
  Input,
} from '@angular/core';
import { MapPlanBase } from './map-plan-base.model';
import { Agency, AgencyService, Project } from 'api';
import { ListTrackingService } from '../utils/list-tracking.service';

@Component({
  selector: 'app-map-plan-project-base',
  templateUrl: './map-plan-project-base.component.html',
})
export class MapPlanProjectBaseComponent
  extends MapPlanBase
  implements AfterViewInit, OnDestroy
{
  @Input() agency: Agency;
  @Input() projects: Project[];

  constructor(
    private agencyService: AgencyService,
    public zone: NgZone,
    @Inject('WINDOW') public window: any,
    public listTrackingService: ListTrackingService,
  ) {
    super(zone, window);
  }

  ngAfterViewInit(): void {
    this.runImgMappingScript();
  }

  ngOnDestroy(): void {
    this.removeImgMappingScript();
  }

  getHref(projectId: number): string {
    return this.agencyService.agency.uuid + '/2d/building/' + projectId;
  }
}
