import { OfferProperty, Agent, Agency } from './index';

type OfferPropertyFields = 'description' | 'sale_price' | 'promo_sale_price' | 'website_latitude' | 'total_size_sqm' | 'project'

export class Offer {
  friendly_id: string;
  message: string;
  agent: Agent;
  agency: Agency;
  exposed_property_fields: OfferPropertyFields[] = [];
  offers_properties: OfferProperty[] = [];
}
