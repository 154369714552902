import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { ApiCallService } from './api-call.service';
import { OfferProperty } from '../models/index';

@Injectable({ providedIn: 'root' })
export class OfferPropertyService extends BaseApiService {
  constructor(private apiCallService: ApiCallService) {
    super();
  }

  sendOfferPropertyFeedback(
    agencyUuid: string,
    uuid: string,
    offerProperty: OfferProperty
  ): Promise<OfferProperty> {
    this.apiCallService.agencyUuid = agencyUuid;
    return this.apiCallService.put(
      '/offers/' + uuid + '/offer_properties/' + offerProperty.id,
      offerProperty
    );
  }
}
