import { Inject, NgZone } from '@angular/core';
declare var jQuery: any;

export class MapPlanBase {
  constructor(public zone: NgZone, @Inject('WINDOW') public window: any) {}

  runImgMappingScript(): void {
    if (!this.window) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      /* tslint:disable */
      var imgmap = { pulseOption: 'first_time' };

      jQuery(function ($) {
        set_image_map('#ap-img', false);
        $('area.selected').mapster('select', true);

        function set_image_map(mapID, click) {
          $(document)
            .find(mapID)
            .each(function () {
              var areas = [];

              $('map[name="' + $(this).attr('usemap').substr(1) + '"]')
                .find('area')
                .each(function () {
                  areas.push({
                    key: $(this).attr('data-mapkey'),
                    toolTip: $(this).attr('data-tooltip'),
                    isSelectable: false,
                    render_highlight: {
                      fillColor: $(this).attr('data-fill-color'),
                      fillOpacity: $(this).attr('data-fill-opacity'),
                      strokeColor: $(this).attr('data-stroke-color'),
                      strokeOpacity: $(this).attr('data-stroke-opacity'),
                      stroke: $(this).attr('data-stroke-width') > 0,
                      strokeWidth: $(this).attr('data-stroke-width'),
                      fade: true,
                      fadeDuration: 300,
                    },
                  });
                });

              var map = this;

              $(this).mapster({
                clickNavigate: click,
                showToolTip: true, // default a fost true
                toolTipContainer: $(
                  '<div id="' +
                    mapID.substr(1) +
                    '" class="imagemapper-tooltip"></div>'
                ),
                toolTipClose: ['area-click', 'area-mouseout'],
                mapKey: 'data-mapkey',
                onClick: AreaClicked,
                onMouseover: function (m) {
                  if (!m.options.toolTip.length)
                    $(map).mapster('tooltip', false);

                  clearTimeout($(map).data('mapster-highlight-timeout'));
                  $(map).mapster('highlight', false);
                  $(map).mapster('highlight', m.key);
                },
                singleSelect: false,
                render_select: {
                  fillColor: 'ddddddd',
                  fillOpacity: '0.8',
                },
                areas: areas,
              });

              if (window.outerWidth <= 1100) {
                //Mark image map as "pulsed" if the first_time pulse option is set.
                if (imgmap.pulseOption == 'first_time')
                  $(this).attr('data-first-mouseenter', true);

                //Prevent duplicate highlights
                $(this).mapster('highlight', false);
                //Highlight all areas
                for (var area in areas) {
                  $(this).mapster('highlight', areas[area].key);
                }

                var map = this;

                // Stop highlighting after a short delay
                // Also fade highlighted areas out rather than hide them instantly
                clearTimeout($(this).data('mapster-highlight-timeout'));
                $(this).data(
                  'mapster-highlight-timeout',
                  setTimeout(function () {
                    $(map)
                      .closest('.imgmap-frontend-image')
                      .find('canvas')
                      .each(function () {
                        $(this)
                          .stop()
                          .animate({ opacity: 1 }, 300, function () {
                            $(map).mapster('highlight', false);
                          });
                      });
                  }, 500)
                );
              }

              // If pulse option is set, initialize it.
              if (imgmap.pulseOption && imgmap.pulseOption != 'never') {
                $(this).mouseenter(function (e) {
                  //Prevent pulse from happening when mouse moves on the image map from tooltip or area. (Prevent mouseenter from "inner" elements)
                  if (
                    $(e.fromElement).hasClass('imagemapper-tooltip') ||
                    $(e.fromElement).is('area')
                  )
                    return;

                  //If this is set true, the pulse has been done already and Wordpress doesn't want to do it again.
                  if (!$(this).attr('data-first-mouseenter')) {
                    //Mark image map as "pulsed" if the first_time pulse option is set.
                    if (imgmap.pulseOption == 'first_time')
                      $(this).attr('data-first-mouseenter', true);

                    //Prevent duplicate highlights
                    $(this).mapster('highlight', false);
                    //Highlight all areas
                    for (var area in areas) {
                      $(this).mapster('highlight', areas[area].key);
                    }

                    var map = this;
                    clearTimeout($(this).data('mapster-highlight-timeout'));
                    $(this).data(
                      'mapster-highlight-timeout',
                      setTimeout(function () {
                        $(map)
                          .closest('.imgmap-frontend-image')
                          .find('canvas')
                          .each(function () {
                            $(this)
                              .stop()
                              .animate({ opacity: 1 }, 300, function () {
                                $(map).mapster('highlight', false);
                              });
                          });
                      }, 500)
                    );
                  }
                });
              }
              //Close tooltips when clicking outside the tooltip.
              $('body').click(function (e) {
                if (
                  !$(e.target).is('.imagemapper-tooltip') &&
                  !$(e.target).closest('.imagemapper-tooltip').length &&
                  $(e.target).attr('data-type') != 'tooltip'
                )
                  $(map).mapster('tooltip', false);

                if (
                  !$(e.target).is('.imgmap-dialog-alt') &&
                  !$(e.target).closest('.imgmap-dialog-alt').length
                )
                  $('.imgmap-dialog-alt').hide(200);
              });
            });
        }
      });

      var resizeTime = 0; // total duration of the resize effect, 0 is instant
      var resizeDelay = 100; // time to wait before checking the window size again
      // the shorter the time, the more reactive it will be.
      // short or 0 times could cause problems with old browsers.

      // Track window resizing events, but only actually call the map resize when the
      // window isn't being resized any more
      jQuery(window).bind('resize', onWindowResize);

      window.setTimeout(function () {
        resize(
          jQuery(document).find('.ap-wrap').width(),
          jQuery(document).find('.ap-wrap').height()
        );
      }, resizeDelay);

      jQuery(document).ready(function () {
        resize(
          jQuery(document).find('.ap-wrap').width(),
          jQuery(document).find('.ap-wrap').height()
        );
      });

      function AreaClicked(data) {
        var type = jQuery('area[data-mapKey=' + data.key + ']').attr(
          'data-type'
        );

        if (type == 'link') {
          var href = jQuery('area[data-mapKey=' + data.key + ']').attr('href');
          window.location.href = href;
        }
      }

      function onWindowResize() {
        var curWidth = jQuery(document).find('.ap-wrap').width(),
          curHeight = jQuery(document).find('.ap-wrap').height(),
          checking = false;
        if (checking) {
          return;
        }
        checking = true;
        window.setTimeout(function () {
          var newWidth = jQuery(document).find('.ap-wrap').width(),
            newHeight = jQuery(document).find('.ap-wrap').height();
          if (newWidth === curWidth && newHeight === curHeight) {
            resize(newWidth, newHeight);
          }
          checking = false;
        }, resizeDelay);
      }

      function resize(maxWidth, _maxHeight) {
        var image = jQuery('img[usemap]'),
          newWidth = 0,
          newHeight = 0;

        newWidth = maxWidth;
        image.mapster('resize', newWidth, newHeight, resizeTime);
      }
      /* tslint:enable */
    });
  }

  removeImgMappingScript(): void {
    if (!this.window) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      /* tslint:disable */
      jQuery(function ($) {
        try {
          $('area.selected').mapster('select', false);
        } catch (e) {
          console.error(e);
        }

        try {
          $('img').mapster('unbind');
        } catch (e) {
          console.error(e);
        }

        try {
          $(document)
            .find('#ap-img')
            .each(function () {
              try {
                $(this).mapster('unbind');
              } catch (e) {
                console.error(e);
              }
            });
        } catch (e) {
          console.error(e);
        }
      });

      const elements = document.getElementsByClassName('ap-wrap');
      for (var i = 0; i < elements.length; i++) {
        elements[i].parentNode.removeChild(elements[i]);
      }
    });
  }
}
