import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HouseDetailsComponent } from './houses/house-details.component';
import { OfferDetailsComponent } from './offers/offer-details.component';
import { AgencyService, OfferService, PropertyService } from 'api';

const routes: Routes = [
  {
    path: ':agency_uuid/locuinte/:friendly_id',
    component: HouseDetailsComponent,
    resolve: {
      agency: AgencyService,
      property: PropertyService
    }
  },
  {
    path: ':agency_uuid/oferte/:uuid',
    component: OfferDetailsComponent,
    resolve: {
      agency: AgencyService,
      offer: OfferService
    }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
