import { Image } from './index';

export class Agent {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  description: string;
  job: string;
  phone: string;
  secondary_phone: string;
  email: string;
  avatar: Image;
}
