export class Image {
  id: number;
  url: string;
  image: string;
  description: string;
  width_320_url: string;
  width_560_url: string;
  width_991_url: string;
  width_1440_url: string;
}
