import { Component, Injector, Input } from '@angular/core';
import { BaseComponent } from '../base-component';
import { AgencyService, Property } from 'api';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
})
export class PropertyCardComponent extends BaseComponent {
  @Input() property: Property;

  constructor(injector: Injector, private agencyService: AgencyService) {
    super(injector);
  }

  propertyAvailability(availability: string): string {
    if (this.canSeeAvailability()) {
      let translated = {};
      translated = {
        available: 'Disponibil',
        sold: 'Vândut',
        unavailable: 'Indisponibil',
        reserved: 'Rezervat',
      };
      return translated[availability];
    } else {
      return;
    }
  }

  canSeeAvailability(): boolean {
    return (
      !this.agencyService.agency.exposed_2d_sections ||
      this.agencyService.agency.exposed_2d_sections?.includes('availability')
    );
  }
}
