<div class="touch hidden-xs">
  <div class="touch-tablet"></div>
  <div class="touch-circle"></div>
  <div class="touch-hand-icon">
    <span class="glyphicon glyphicon-hand-up" aria-hidden="true"></span>
  </div>
  <div class="touch-hand">
    <img src="assets/icons/touch-hand.png" />
  </div>
</div>
