<main>
  <header
    *ngIf="offer.agent"
    class="custom-container no-page-break contact-agent flex flex-col md:flex-row gap-2.5 justify-between print:flex-row"
  >
    <div class="md:hidden lg:block print:hidden">
      <h2 class="inline-block">Contact agent</h2>
    </div>
    <div
      class="flex flex-row items-center gap-2.5"
      *ngIf="offer.agent.full_name"
    >
      <mat-icon>person</mat-icon>
      <div>
        {{ offer.agent.full_name }}
      </div>
    </div>
    <div *ngIf="offer.agent.phone" class="flex flex-row items-center gap-2.5">
      <mat-icon>phone</mat-icon>
      <div>
        <a class="block" [href]="'tel:' + offer.agent.phone">
          {{ offer.agent.phone | beautify }}
        </a>
        <a class="block" [href]="'tel:' + offer.agent.secondary_phone">
          {{ offer.agent.secondary_phone | beautify }}
        </a>
      </div>
    </div>
    <a
      *ngIf="offer.agent.email"
      class="flex flex-row items-center gap-2.5"
      [href]="'mailto:' + offer.agent.email"
      target="_blank"
    >
      <mat-icon>email</mat-icon>
      <div>
        {{ offer.agent.email }}
      </div>
    </a>
  </header>

  <div
    *ngIf="offer.message"
    class="message custom-container"
  >{{ offer.message }}</div>

  <div
    *ngFor="let offer_property of offer.offers_properties; let last = last"
    [ngClass]="{ 'page-break-after': !last}"
  >
    <app-house-details
      [property]="offer_property.property"
      [withLike]="true"
      [likeDislike]="offer_property.status"
      (feedback)="sendFeedback(offer_property, $event)"
    >
    </app-house-details>
  </div>
</main>
